.rss-list {
  text-align: left;
  padding: rem-calc(16);
  color: white;
  h2 {
    text-align: left;
    font-size: 1.5em;
    font-weight: bold;
    background: none;
    margin: 0em 0em 1em 1em;
    i {
      margin-right: 0.5em;
    }
  }
  .rss-wrapper {
    margin-left: 1em;
    border-top: 1px solid $main-color;
    padding-top: 1em;
    h3 {
      text-align: left;
      font-size: 1.2em;
      font-weight: bold;
      background: none;
      margin: 1em 0em 1em 0em;
      i {
        margin-right: 0.5em;
      }
    }
    li {
      color: #fff;
    }
  }
}
