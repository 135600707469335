body {
  margin: 0 !important;
}
.row {
  max-width: 500rem !important;
}
.header {
  .jumbotron-wrapper {
    padding: 0 !important;
    .jumbotron {
      border-radius: 0 !important;
      border: 0px solid $main-color !important;
      margin-top: 0 !important;
    }
  }
}
.main-content {
  .center-column, .aside-left, .aside-right {
    border: 0px solid $main-color !important;
    background-color: rgba(50,50,50,0.5) !important;
    border-radius: 0 !important;
  }
  .aside-left {
    .categories {
      .category-all {
        border: 0px solid $main-color !important;
      }
      .accordion {
        .accordion-title {
          border: 0px solid $main-color !important;
        }
        .accordion-content {
          border: 0px solid $main-color !important;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }
  .center-column {
    .pagination {
      li {
        &.current {
          padding: 0;
        }
        &.ellipsis::after {
          display: none;
          color: $main-color;
        }
        a {
          padding: rem-calc(4) rem-calc(8);
        }
      }
    }
    .pagination_up {
      padding-top: rem-calc(10) !important;
      .pagination {
        border: 0px solid $main-color !important;
        margin-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
    .pagination_down {
      padding-bottom: rem-calc(10) !important;
      .pagination {
        border: 0px solid $main-color !important;
      }
    }
    .content_list {
      .content_list_item {
        border: 0px solid $main-color !important;
        background-color: rgba(50,50,50,0.75) !important;
      }
    }
    .content {
      .comments {
        border: 0px solid $main-color !important;
        .comment-new {
          border: 0px solid $main-color !important;
        }
        .comment-wrapper {
          border: 0px solid $main-color !important;
          background-color: rgba(50,50,50,0.75) !important;
          padding-bottom: 0 !important;
          .comment {
            margin: 0 !important;
            .comment-right {
              .comment-right-bottom {
                .text {
                  background-color: rgba(100,100,100,0.25) !important;
                }
              }
            }
          }
        }
      }
      .content-body {
        .photo-list {
          .image-wrapper {
            border: 0px solid $main-color !important;
          }
        }
      }
    }
    .privacy-policy, .terms {
      text-align: left;
    }
  }
  .aside-right {
    .main-menu {
      border: 0px solid $main-color !important;
    }
    .square_banner_1 {
      a {
        border: 0px solid $main-color !important;
      }
    }
  }
}
.footer-wrapper {
  border: 0px solid $main-color !important;
  background-color: rgba(50,50,50,0.5) !important;
  border-radius: 0 !important;
}

.vjs-suggested-video-endcap-link {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: rem-calc(5) !important;
  .vjs-suggested-video-endcap-img {
    height: auto !important;
    width: auto !important;
  }
}
