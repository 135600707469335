.aside-left {
  @media screen and #{breakpoint(medium down)} {
    &.hidden {
      display: none;
    }
  }
  .categories {
    .fa {
      padding-right: 0.5em;
    }
    .category-active {
      color: red !important;
    }
    .category-all, .search, .rss, .comments {
      color: $main-color;
      background: none;
      border: 0px solid $main-color;
      font-size: rem-calc(20);
      font-weight: bold;
      text-align: left;
      display: block;
      padding: 1.25rem 1rem;
      line-height: 1;
      font-size: 1.25rem;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        color: $main-color;
      }
      a:hover {
        color: #800000;
      }
    }
    .category-all {
      border-bottom: 1px solid $main-color;
    }
    .accordion {
      background: none;
      margin-bottom: 0;
      .accordion-title, .accordion-title:hover, .accordion-title:focus {
        color: $main-color;
        background: none;
        border: 0px solid $main-color;
        font-size: rem-calc(20);
        font-weight: bold;
        text-align: left;
        border-top: 1px solid $main-color;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .accordion-title:hover, .accordion-title:focus {
        color: #800000;
      }
      .accordion-content {
        color: $main-color;
        background: none;
        border: 0px solid $main-color;
        border-bottom: 1px solid $main-color;
        border-top: 1px solid $main-color;
        ul {
          list-style-type: none;
          font-size: rem-calc(20);
          li {
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            a:hover {
              color: #800000;
            }
            a {
              color: #00ff00;
            }
          }
        }
      }
      .top-tags {
        .accordion-content {
          border-bottom: 0px solid $main-color;
          ul {
            li {
              a {
                color: #2199e8;
              }
              a:hover {
                color: #800000;
              }
            }
          }
        }
      }
    }
  }
}
