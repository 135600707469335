.footer-wrapper {

  font-weight: bold;
  color: $main-color;
  line-height: 0.8em;
  //background-color: black;
  //box-shadow: 10px 10px 5px #222222;
  border-radius: 10px;
  border: 2px solid $main-color;
  padding: rem-calc(16);
  margin-top: rem-calc(32);
  margin-bottom: rem-calc(16);


  h3 {
    color: $main-color;
    text-align: center;
    font-weight: bold;
    margin-top: rem-calc(50);
  }
  i.fa {
    font-size: 5em;
    width: 100%;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
  }
  h4 {
    color: $main-color;
    font-size: 1em;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 2.5rem;
    margin-bottom: 10px;
  }
  .footer-bottom {
    padding: 24px 0 10px;
    .sc-title {
      font-size: 1.25em;
      font-weight: 700;
      padding: 0.2em 0.4em;
      color: #fff;
    }
    ul {
      display: inline-block;
      margin-left: 0.5em;
      list-style-type: none;
      li {
        display: inline-block;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.75em;
        padding: 0.2em 0.4em;
        a {
          color: #fff;
        }
      }
      &.home-social {
        li {
          font-size: 2em;
          a {
            border: 2px solid #fff;
            border-radius: 50%;
            padding: 0.2em;
            width: 1.5em;
            text-align: center;
          }
        }
      }
    }
    .copyright {
      font-size: 0.75em;
      color: #eee;
      margin: 0.5em 0 0;
    }
  }
}
