.content_list {
  margin: 0;
  .content_list_item {
    list-style-type: none;
    text-align: left;
    margin: rem-calc(16);
    padding: rem-calc(16);
    border-bottom: 1px solid $main-color;
    .col_1 {
      position: relative;
      .preview_image:before {
        content: "";
        display: block;
        padding-top: 56.25%;
      }
      .content-size {
        position: absolute;
        bottom: rem-calc(0);
        right: rem-calc(10);
        @media screen and #{breakpoint(medium up)} {
          right: rem-calc(15);
        }
        background-color:rgba(0, 0, 0, 0.5);
        color: #fff;
        padding: rem-calc(2) rem-calc(5);
      }
      .content-rating {
        position: absolute;
        top: rem-calc(5);
        right: rem-calc(20);
        @media screen and #{breakpoint(medium up)} {
          right: rem-calc(15);
        }
        color: orange;
      }
      .preview_image {
        position: relative;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        text-align: center;
        overflow: hidden;
        background-color: black;
        //border: 1px solid $main-color;
        transition: all 0.5s ease-in;
        &.blur {
          filter: blur(rem-calc(8));
          -moz-filter: blur(rem-calc(8));
          -webkit-filter: blur(rem-calc(8));
          -ms-filter: blur(rem-calc(8));
        }
      }
    }
    .col_2 {
      display: flex;
      flex-flow: column;
      text-align: left;
      vertical-align: top;
      font-size: rem-calc(14);
      .preview_text_up {
        @media screen and #{breakpoint(medium down)} {
          padding-top: rem-calc(5);
        }
        flex-grow: 1;
        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          a {
            color: $main-color;
            font-size: 1em;
            font-weight: bold;
            text-decoration: underline;
            font-size: rem-calc(16);
          }
        }
      }
      .preview_text_down {
        .user_name {
          font-weight: bold;
        }
        label {
          display: inline-block;
          font-size: rem-calc(14);
          font-weight: bold;
          color: $main-color;
          margin-right: rem-calc(12);
        }
      }
    }
  }
  .content_list_item:last-child {
      border-bottom: 0px solid $main-color;
  }
}
