.search {
  .advanced-toggle {
    cursor: pointer;
    text-align: left;
    font-size: rem-calc(16);
    font-weight: bold;
    .fa {
      padding-right: rem-calc(5);
    }
  }
  .fieldset {
    border: 1px solid #fff;
    text-align: left;
    .legend {
      background-color: transparent;
      font-size: rem-calc(16);
      font-weight: bold;
    }
    .switch-paddle {
      background-color: #ffbb99;
    }
    input:checked ~ .switch-paddle {
      background-color: $main-color;
    }
  }
}
