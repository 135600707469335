.content {
  .content-header {
    text-align: left;
    padding: 0 rem-calc(20) 0 rem-calc(20);
    .row {
      margin: 0;
    }
    .content-name {
      font-size: rem-calc(28);
      font-weight: bold;
      color: $main-color;
    }
    .user {
      padding: rem-calc(10) rem-calc(0) rem-calc(10) rem-calc(0);
      .name {
        font-size: rem-calc(25);
        font-weight: bold;
      }
      .avatar {
        display: inline-block;
        width: rem-calc(64);
        height: rem-calc(64);
        margin-right: rem-calc(20);
      }
    }
    .view_num_rating {
      .view_num {
        font-size: rem-calc(25);
        text-align: right;
        .fa {
          font-size: rem-calc(25);
          background: none;
          color: $main-color;
        }
      }
      .content-rating {
        text-align: right;
        .content-avg-rating {
          color: $main-color;
        }
        .content-my-rating {
          color: white;
          &.rated {
            color: green;
          }
          &.error {
            color: red;
          }
        }
      }
    }
  }
  .content-body {
    padding: rem-calc(10);
    .photo-list {
      padding: rem-calc(5);
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      .image-wrapper {
        padding: 3px;
        margin: 0px 0px 3px 0px;
        border: 1px solid $main_color;
      }
    }
    .video-js {
      .preroll-skip-button {
          position: absolute;
          top: rem-calc(10);
          right: rem-calc(10);
          font-size: rem-calc(20);
          padding: rem-calc(5) rem-calc(20) rem-calc(5) rem-calc(20);
          &.enabled:hover {
            background-color: #999;
            cursor: pointer;
          }
      }
      .preroll-blocker {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .vjs-fullscreen {
          position: fixed;
          overflow: hidden;
          z-index: 1000;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          width: 100% !important;
          height: 100% !important;
        }
    }
  }
  .content-info, .content-share {
    text-align: left;
    padding: rem-calc(0) rem-calc(20) rem-calc(0) rem-calc(20);
  }
  .content-info {
    padding-top: rem-calc(10);
    .description, .content-tags, .category, .date, .download {
      .label {
        display: inline-block;
        font-size: rem-calc(14);
        font-weight: bold;
        color: $main-color;
        margin-right: rem-calc(6);
        background: none;
      }
    }
    .description {
      padding-bottom: rem-calc(10);
    }
    .content-tags {
      .label {
        color: #1585cf;
      }
      .tag-list {
        display: inline;
      }
    }
    .download {
      a {
        text-decoration: underline;
      }
    }
  }
  .content-share {
    .share-buttons {
      padding-top: rem-calc(10);
      padding-bottom: rem-calc(10);
    }
  }
  .comments {
    color: white;
    border-top: 1px solid $main-color;
    padding: rem-calc(10);
    text-align: left;
    .name {
      color: $main-color;
    }
    .comments-title {
      padding-left: rem-calc(10);
      text-align: left;
      font-size: rem-calc(20);
      color: $main-color;
      font-weight: bold;
      .fa {
        padding-right: rem-calc(5);
      }
    }
    .comment-button {
      margin: 0;
      padding: rem-calc(10);
      .link-new {
        color: green;
        border: 1px solid green;
        font-weight: bold;
        border-radius: rem-calc(5);
        margin-top: rem-calc(10);
      }
      .comment-error-login {
        color: red;
        font-size: rem-calc(20);
        font-weight: bold;
        margin: rem-calc(10) 0 rem-calc(20) 0;
        .fa {
          margin-right: rem-calc(5);
        }
      }
    }
    .comment-new {
      margin: 0;
      padding-bottom: rem-calc(5);
      border-bottom: 1px solid $main-color;
      .avatar {
        padding: 0;
      }
      .sceditor-container {
        z-index: 1;
      }
      .link-send, .link-cancel {
        color: $main-color;
        border: 1px solid $main-color;
        font-weight: bold;
        border-radius: rem-calc(5);
        margin-top: rem-calc(10);
      }
    }
    .sceditor-container {
      background-color: #444;
      iframe {
        background-color: #444;
      }
    }
    @import "comments";
  }
}
