.main-menu {
  color: white;
  padding: rem-calc(10) rem-calc(20) rem-calc(20) rem-calc(20);
  border-bottom: 1px solid $main-color;
  legend {
    text-align: left;
    font-size: 1.2em;
    font-weight: bold;
    background: none;
    i {
      margin-right: 0.5em;
    }
  }
  .form-group {
    padding: 0;
    .button {
      width: 100%;
      border-radius: 5px;
      color: $main-color;
      border-color: $main-color;
      .fa {
        padding-right: 0.5em;
      }
      &:hover {
        color: red;
      }
    }
  }
}
