.jumbotron {
  font-weight: bold;
  color: $main-color;
  line-height: 0.8em;
  border-radius: 10px;
  border: 2px solid $main-color;
  padding: 0;
  margin-top: rem-calc(10);
  height: rem-calc(48);
  @media screen and #{breakpoint(large up)} {
    height: rem-calc(128);
  }
  .jumbotron-row {
    height: 100%;
    margin: 0;
    .logo2-wrapper {
      @media screen and #{breakpoint(large up)} {
        padding-top: rem-calc(4);
        padding-bottom: rem-calc(4);
      }
    }
    a {
      color: $main-color;
      .logo1, .logo2, .logo3 {
        margin: 0em 0em 0em 0em;
        display: block;
        background-size: contain;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
      }
      .logo1 {
        background-image: url('/images/header-1-1.png');
      }
      .logo2 {
        @for $i from 1 through 7 {
          &.v#{$i} {
            background-image: url('/images/header-2-#{$i}.png');
          }
        }
      }
      .logo3 {
        background-image: url('/images/header-1-3.png');
      }
    }
    .aside-left-button {
      margin-top: rem-calc(12);
      font-size: rem-calc(16);
      .list-icon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        width: 20px;
        height: 16px;
        color: #fff;
        font-size: 1.5em;
      }
    }
    .menu-button {
      margin-top: rem-calc(12);
      font-size: rem-calc(16);
      padding-left: 0;
      .mobile-menu-icon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        width: 20px;
        height: 16px;
        color: #fff;
        font-size: 1.5em;
      }
    }
  }
}
