.privacy-policy, .privacy-policy2 {
  padding: 1rem;
  table {
    background-color: transparent;
    border-spacing: 0;
    margin: 2rem 0rem 2rem 0rem;
    tbody {
      background-color: transparent;
      border: 0px solid #000;
    }
    tr, th, td {
      background-color: transparent;
    }
    td:first-child, th:first-child {
      padding-left: 0;
    }
    td:last-child, th:last-child {
      padding-right: 0;
    }
    td, th {
      border-bottom: 1px solid #e1e1e1;
      padding: 1.2rem 1.5rem;
      text-align: left;
    }
  }
}

.privacy-policy2 {
  h1 {
    font-size: 1.5rem;
    text-align: left;
  }
  table {
    word-break: break-all;
  }
}

.privacy-policy3 {
  iframe {
    width: 100%;
    min-height: 64rem;
  }
}

.privacy-policy4 {
  padding: 1rem;
  h1 {
    font-size: 1.5rem;
    text-align: left;
  }
  p {
    text-align: left;
    margin: 1em 0em 0em 0em;
  }
}