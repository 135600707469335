.square_banner_1 {
  a {
    border-bottom: 1px solid $main-color;
    display: inline-block;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      padding: rem-calc(20);
      border: 0;
    }
  }
}
