.jquery-modal {
  padding: rem-calc(5);
  .user-banned, .ip-banned {
    width: auto;
    @media screen and #{breakpoint(small only)} {
      padding-top: rem-calc(140);
    }
    @media screen and #{breakpoint(medium only)} {
      padding-top: rem-calc(135);
    }
    @media screen and #{breakpoint(large up)} {
      padding-top: rem-calc(120);
    }
    background: url("/images/skull.png") no-repeat 10px 10px;
    .site_name {
      font-weight: bold;
      color: orange;
      display: inline-block;
      @media screen and #{breakpoint(small only)} {
        font-size: rem-calc(32);
      }
      @media screen and #{breakpoint(medium only)} {
        font-size: rem-calc(50);
      }
      @media screen and #{breakpoint(large up)} {
        font-size: rem-calc(80);
      }
    }
    .button {
      background-color: #99E718;
      text-shadow: 1px 1px 2px #004600;
      color: black;
      font-size: 1.25em;
    }
  }
}
