.upload {
  .tag-plus {
    text-align: left;
    label {
      display: inline-block;
    }
  }
  .tag-input {
    text-align: left;
    input {
      display: inline-block;
      width: 95%;
      margin-right: rem-calc(5);
    }
    span.help-block {
      width: 95%;
    }
  }
}
.upload-data {
  label {
    font-weight: bold;
  }
  value {
    display: block;
    text-align: left;
  }
  .dropzone {
    background: none;
    border: 1px solid white;
    margin-bottom: rem-calc(20);
  }
}
.login, .connect, .register, .password, .create-user, .edit-user, .upload, .upload-data, .search, .contact {
  color: white;
  @media screen and #{breakpoint(small only)} {
    padding: 0 rem-calc(10) 0 rem-calc(10);
  }
  @media screen and #{breakpoint(medium up)} {
    padding: rem-calc(10) rem-calc(20) rem-calc(20) rem-calc(20);
  }
  h2 {
    text-align: left;
    font-size: 1.5em;
    font-weight: bold;
    background: none;
    margin: 1em 0em 1em 1em;
    i {
      margin-right: 0.5em;
    }
  }
  h3 {
    text-align: left;
    font-size: 1.2em;
    font-weight: bold;
    background: none;
    margin: 1em 0em 1em 0em;
    i {
      margin-right: 0.5em;
    }
  }
  .form-register, .form-login, .form-password, .form-search, .form-contact, .form-edit-user, .form-upload {
    border-top: 1px solid $main-color;
    padding: rem-calc(20) rem-calc(20) rem-calc(20) rem-calc(20);
    margin-top: rem-calc(20);
    .switch {
      margin-bottom: 0;
      padding-left: 0;
    }
    .send-button {
      margin-top: 3em;
      input.button {
        font-size: 1.25em;
      }
    }
  }
  .form-edit-user-avatar {
    &.dropzone {
      color: #000;
    }
  }
  @media #{$small-only} {
    .g-recaptcha {
      transform:scale(0.9);
      transform-origin: 0 0;
    }
  }
  .row {
    margin-bottom: rem-calc(10);
  }
  .button {
    width: 100%;
    border-radius: 5px;
    color: $main-color;
    border-color: $main-color;
    .fa {
      padding-right: 0.5em;
    }
    &:hover {
      color: red;
    }
  }
  .label {
    font-size: 1.1em;
    display: block;
    background: none;
    color: white;
    text-align: left;
    padding-left: 0;
  }
  input, select, textarea {
    font-weight: bold;
    margin: 0;
  }
  span.help-block {
    background-color: red;
    color: white;
    padding: 0.1em;
    width: 100%;
    display: block;
    border: 1px solid red;
  }
  .form-group {
    text-align: left;
    margin-bottom:1em;
  }
}
