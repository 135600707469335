.comment-wrapper {
  margin: rem-calc(20) 0 rem-calc(20) 0;
  padding-bottom: rem-calc(20);
  border-bottom: 1px solid $main-color;
  .comment, .reply {
    .text {
      s {
        text-decoration: line-through;
      }
      em {
        text-decoration: underline;
      }
    }
  }
  .comment {
    width: 100%;
    margin: 0 0 rem-calc(5) 0;
    .comment-left {
      padding: 0;
    }
    .comment-right {
      .comment-right-top {
        font-size: rem-calc(13);
        margin-left: 0;
        .date {
          color: #ddaa22;
        }
        .vote {
          .vote-sum {
            &.white { color: white; }
            &.red { color: red; }
            &.green { color: green; }
            margin-right: rem-calc(5);
          }
          .vote-thumbs {
            font-size: rem-calc(18);
            .fa {
              color: #aaaaaa;
              &.voted {
                color: $main-color;
              }
            }
          }
        }
        .reply-link {
          a {
            color: #aaaaaa;
          }
        }
        .spam-link {
          a {
            color: #aaaaaa;
          }
        }
      }
      .comment-right-bottom {
        margin-left: 0;
        margin-right: 0;
        .text {
          background-color: #444;
          padding: rem-calc(7);
          font-size: rem-calc(16);
          word-wrap: break-word;
          &.censored {
            color: #ff5555;
          }
        }
      }
    }
  }
  .replies {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    .comment-new {
      border-bottom: 0px solid white;
    }
    .reply {
      margin: 0 0 rem-calc(5) 0;
      background-color: #222;
      .reply-left {
        padding: 0;
      }
      .reply-right {
        padding-right: 0;
        .reply-right-top {
          font-size: rem-calc(13);
          .date {
            color: #666666;
          }
          .spam-link {
            padding-right: rem-calc(5);
            a {
              color: #aaaaaa;
            }
          }
        }
        .text {
          font-size: rem-calc(15);
          word-wrap: break-word;
          &.censored {
            color: #ff0000;
          }
        }
      }
    }
  }
}
