$main-color: orange;
//$main-color: yellow;
//$main-color: #00ff01;

// FOUC
.no-js {
  .accept-terms, .user-banned, .ip-banned {
    opacity: 0;
  }
}

body {
    height: 100%;
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 100%;
    color: #FFF;
    background: #000 url('/images/background.jpg') repeat-y center 0px;
    text-align: center;
}

@import "accept-terms";
@import "ban";
@import "header";
@import "modals";
@import "mobile-banner";
@import "return-to-top";

.mobile-menu {
  padding-top: rem-calc(8);
  @import "main-menu";
  .main-menu {
    border: 2px solid $main-color;
    border-radius: 10px;
  }
  &.hidden {
    display: none;
  }
}

.callout {
  margin-top: 1em;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 1.4em;
  button.close-button {
      color: white;
  }
  &.alert {
    background-color: #ff5555;
  }
  &.primary {
    background-color: #5eff5e;
  }
}
.main-content {
    margin-top: rem-calc(8);
    > .row {
        .columns {
            .center-column, .aside-left, .aside-right {
                min-height: rem-calc(80);
                border-radius: rem-calc(10);
                border: 2px solid $main-color;
                margin-bottom: rem-calc(8);
            }
            @import "aside-left";
            @import "center-column";
            @import "aside-right";
        }
    }
}

@import "footer";

@import "mod";
