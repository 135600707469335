.pagination {
  li {
    &.current {
      background: none;
      border: 1px solid $main-color;
    }
    &.ellipsis {
      color: $main-color;
    }
    a {
      color: $main-color;
    }
  }
}
.pagination_up {
  .pagination {
    margin-top: rem-calc(10);
    padding-bottom: rem-calc(10);
    border-bottom: 1px solid $main-color;
  }
}
.pagination_down {
  .pagination {
    border-top: 1px solid $main-color;
    padding-top: rem-calc(10);
    margin-bottom: rem-calc(10);
  }
}
