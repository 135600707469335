.user-data {
  border-bottom: 1px solid $main-color;
  text-align: left;
  padding: rem-calc(20);
  span.fa {
    display: inline-block;
    color: $main-color;
    width: rem-calc(32);
  }
  .content-label {
    margin-top: rem-calc(10);
  }
}
