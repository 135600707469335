.comments {
  text-align: left;
  padding: rem-calc(16);
  h2 {
    text-align: left;
    font-size: 1.5em;
    font-weight: bold;
    background: none;
    margin: 0em 0em 1em 1em;
    i {
      margin-right: 0.5em;
    }
  }
  .comment-list-wrapper {
    border-top: 1px solid $main-color;
    .pagination_up, .pagination_down {
      text-align: center;
    }
    .comment-wrapper {
      border: 0px solid $main-color;
      background-color: rgba(50, 50, 50, 0.75);
      padding-bottom: 0;
      margin: 1.25rem 0 1.25rem 0;
      .comment {
        margin-left: 0;
        .comment-left {
          padding: 0;
        }
        .comment-right {
          .comment-right-top {
            font-size: 0.8125rem;
            margin-left: 0;
            .name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .date {
              color: #ddaa22;
            }
            .content-category {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #ff8822;
            }
            .content-name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .comment-right-bottom {
            margin-left: 0;
            margin-right: 0;
            .text {
              padding: 0.4375rem;
              font-size: 1rem;
              word-wrap: break-word;
              background-color: rgba(100, 100, 100, 0.25);
            }
          }
        }
      }
    }
  }
}
