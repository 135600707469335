.top-related {
  text-align: left;
  padding: 0 rem-calc(20) rem-calc(0) rem-calc(20);
  margin-bottom: rem-calc(10);
  .header {
    font-size: rem-calc(20);
    font-weight: bold;
    padding-left: rem-calc(10);
    padding-bottom: rem-calc(10);
    .fa {
      padding-right: rem-calc(10);
    }
  }
  .top-item {
    padding-bottom: rem-calc(10);
    .col_1 {
      .preview_image:before{
        content: "";
        display: block;
        padding-top: 56.25%;
      }
      .preview_image {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        text-align: center;
        overflow: hidden;
        background-color: black;
        //border: 1px solid $main-color;
        img {
          width: auto;
          max-width:100%;
          max-height:100%;
          vertical-align: middle;
        }
      }
    }
    .col_2 {
      padding-left: 0;
      font-size: rem-calc(12);
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        a {
          color: $main-color;
        }
      }
      label {
        color: $main-color;
        padding-right: rem-calc(5);
      }
    }
  }
}
