.center-column {
  @import "forms";
  @import "search";
  @import "rss";
  @import "pagination";
  @import "content_list";
  @import "user_data";
  @import "content";
  @import "comment-list";
  @import "privacy-policy";
}
