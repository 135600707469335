
.jquery-modal {
//  background-image: url("/images/background.jpg");
  padding: rem-calc(5);
  color: orange;
  .spam-modal, .vote-modal, .comment-modal, .score-modal {
    border: 2px solid orange;
    background: black;
    width: auto;
    label {
      color: white;
      font-weight: bold;
      font-size: rem-calc(20);
      margin: rem-calc(20) 0 rem-calc(40) 0;
      &.success {
        color: green;
      }
      &.error {
        color: red;
      }
    }
    a {
      color: white;
      &.button {
        border-radius: 5px;
        color: white;
        font-weight: bold;
        background-color: $main-color;
        border-color: orange;
      }
    }
  }
}
